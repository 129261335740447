import React from 'react'

interface Props {
  tech: string
  img: string
}

export const Tech = ({ tech, img }: Props) => {
  return (
    <div className="py-4 px-5 border border-bg3 rounded flex items-center gap-2 mb-4 md:mb-0">
      <img className="w-14" src={img} alt={tech} />
      {tech}
    </div>
  )
}
export default Tech

import React from 'react'
import { FaGithub } from 'react-icons/fa'
import { FaLinkedin } from 'react-icons/fa'
import { FaInstagram } from 'react-icons/fa'
import { IoIosArrowRoundDown } from 'react-icons/io'
import { IoIosArrowForward } from 'react-icons/io'
import { IoIosArrowUp } from 'react-icons/io'
import Assets from '../components/assets'

import Tech from '../components/tech-icon'
import TechBullet from '../components/tech-bullet'

export function Home() {
  return (
    <div>
      <Assets />
      <div className="home-page text-lg absolute top-0 left-0 w-full z-20">
        <div className="border-b border-bg4">
          <div className="container flex align-left">
            <div className="pt-12 pb-12 pr-52 border-r border-bg4">
              <div className="font-oswald font-bold text-2xl ">
                <span className="text-pri">ROLL_</span>
                <span className="text-sec">The_Coder</span>
              </div>
            </div>
            <div className="flex-1" />
          </div>
        </div>
        <div className="container mb-24 md:mb-72">
          <div className="mb-12 text-5xl sm:text-6xl md:text-8xl font-bold mt-52">
            <div className="text-sec">FULL STACK</div>
            <div className="text-pri">DEVELOPER</div>
          </div>
          <div className="sm:flex gap-4 mb-12">
            <a
              className="btn btn-primary flex items-center gap-2 mb-4 md:mb-0"
              href="https://github.com/rollrodrig"
              target="_blank"
            >
              <FaGithub /> Github
            </a>
            <a
              className="btn btn-secondary flex items-center gap-2 mb-4 md:mb-0"
              href="https://www.linkedin.com/in/rolly-rodriguez-02713b7a/"
              target="_blank"
            >
              <FaLinkedin /> LinkedIn
            </a>
            <a
              className="btn btn-third flex items-center gap-2 mb-4 md:mb-0"
              href="https://www.instagram.com/rollthecoder/"
              target="_blank"
            >
              <FaInstagram /> Instagram
            </a>
          </div>
          <div className="text-sec text-3xl font-bold mb-3">
            +12 years <span className="text-pri">experience</span>
          </div>
          <div className="mb-4">
            Skilled developer building project from ideas to{' '}
            <span className="text-sec font-bold">deployment</span>
          </div>
          <div className="flex justify-center items-center w-24 h-24 bg-bg2  text-text text-6xl mt-20 ml-20 md:ml-80">
            <IoIosArrowRoundDown />
          </div>
        </div>
        <div className="container mb-24 md:mb-72">
          <div className="md:flex justify-between">
            <div className="md:w-96 mb-6">
              <div className="text-4xl md:text-6xl font-bold">
                About <span className="text-pri">me</span>
              </div>
              <div>
                Full Stack Developer with{' '}
                <span className="font-bold">
                  12 years of experience in web development.
                </span>{' '}
                <br />
                Expert in technologies like:
              </div>
            </div>
            <div className="md:w-[700px]">
              <div className="md:grid grid-cols-3 gap-5 mb-12">
                <Tech tech="React" img="/assets/tech/ic-react.png" />
                <Tech tech="Vue" img="/assets/tech/ic-vue.png" />
                <Tech tech="Javascript" img="/assets/tech/ic-js.png" />
                <Tech tech="Python" img="/assets/tech/ic-python.png" />
                <Tech tech="Node" img="/assets/tech/ic-node.png" />
                <Tech tech="Postgres" img="/assets/tech/ic-postgres.png" />
              </div>
              <div className="flex justify-between">
                <div>
                  <TechBullet tech="Javascript" />
                  <TechBullet tech="Typescript" />
                  <TechBullet tech="React" />
                  <TechBullet tech="React hooks" />
                  <TechBullet tech="Redux" />
                  <TechBullet tech="Redux-thunk" />
                  <TechBullet tech="NextJs" />
                  <TechBullet tech="Vue 2, 3" />
                  <TechBullet tech="D3" />
                  <TechBullet tech="Reselect" />
                </div>
                <div>
                  <TechBullet tech="Babylon JS" />
                  <TechBullet tech="Webpack" />
                  <TechBullet tech="Unit Testing" />
                  <TechBullet tech="e2e Testing" />
                  <TechBullet tech="React Testing Library" />
                  <TechBullet tech="Jest" />
                  <TechBullet tech="Mocha" />
                  <TechBullet tech="Chai" />
                  <TechBullet tech="Cypress" />
                  <TechBullet tech="pytest" />
                </div>
                <div>
                  <TechBullet tech="Python" />
                  <TechBullet tech="Flask" />
                  <TechBullet tech="Node" />
                  <TechBullet tech="Express" />
                  <TechBullet tech="Php" />
                  <TechBullet tech="Laravel" />
                  <TechBullet tech="Mysql" />
                  <TechBullet tech="Docker" />
                  <TechBullet tech="git" />
                  <TechBullet tech="Restful API" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-24 md:mb-72">
          <div className="text-4xl md:text-6xl font-bold mb-12 text-center">
            What can I <span className="text-pri">do for you?</span>
          </div>
          <div className="flex justify-center">
            <div className="flex justify-between max-w-[800px] w-full items-center">
              <div className="flex flex-col items-center">
                <div className="flex justify-center items-center border rounded-lg border-bg3 w-32 h-32 md:w-64 md:h-64 mb-2">
                  <img
                    src="/assets/idea.png"
                    alt="startup idea"
                    className="w-16 md:w-28"
                  />
                </div>
                <div className="text-center text-sm md:text-lg">
                  Transform your ideas
                </div>
              </div>
              <div className="w-full border-t-2 border-dashed border-bg3 h-1 flex-1 relative">
                <IoIosArrowForward className="absolute right-[-6px] top-[-10px] text-bg3" />
              </div>
              <div className="flex flex-col items-center">
                <div className="flex justify-center items-center border rounded-lg border-bg3 w-32 h-32 md:w-64 md:h-64 mb-2">
                  <img
                    src="/assets/device.png"
                    alt="startup device"
                    className="w-16 md:w-28"
                  />
                </div>
                <div className="text-center text-sm md:text-lg">
                  Into real products
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-24 md:mb-72">
          <div className="max-w-[800px] w-full">
            <div className="text-right ">
              <div>Did I said that I have</div>
              <div className="text-sec text-xl md:text-3xl font-bold mb-3">
                +12 years <span className="text-pri">experience</span>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-24 md:mb-72">
          <div className="text-4xl md:text-6xl font-bold mb-12 text-center">
            How is my <span className="text-pri">work process?</span>
          </div>
          <div className="flex justify-center">
            <div className="flex justify-between max-w-[800px] w-full">
              <div className="flex flex-col items-center">
                <div className="flex justify-center items-center border rounded-lg border-bg3 w-20 h-20 md:w-36 md:h-36 mb-2 bg-bg1">
                  <img
                    src="/assets/lightbulb.png"
                    alt="idea"
                    className="w-16 md:w-24"
                  />
                </div>
                <div className="text-sm md:text-lg text-center">Analysis</div>
              </div>
              <div className="w-full border-t-2 border-dashed border-bg3 h-1 flex-1 mt-8 md:mt-16 relative">
                <IoIosArrowForward className="absolute right-[-6px] top-[-10px] text-bg3" />
              </div>
              <div className="flex flex-col items-center">
                <div className="flex justify-center items-center border rounded-lg border-bg3 w-20 h-20 md:w-36 md:h-36 mb-2 bg-bg1">
                  <img
                    src="/assets/coffee.png"
                    alt="delvelopment"
                    className="w-16 md:w-24"
                  />
                </div>
                <div className="text-sm md:text-lg text-center">
                  Development
                </div>
              </div>
              <div className="w-full border-t-2 border-dashed border-bg3 h-1 flex-1 mt-8 md:mt-16 relative">
                <IoIosArrowForward className="absolute right-[-6px] top-[-10px] text-bg3" />
              </div>
              <div className="flex flex-col items-center">
                <div className="flex justify-center items-center border rounded-lg border-bg3 w-20 h-20 md:w-36 md:h-36 mb-2 bg-bg1">
                  <img
                    src="/assets/airplane.png"
                    alt="airplane"
                    className="w-14 md:w-24"
                  />
                </div>
                <div className="text-sm md:text-lg text-center">
                  Deployment
                  <br />
                  <span className="text-sec">...almost daily</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="flex justify-center max-w-[800px] w-full">
              <div className="flex justify-between max-w-[90%] w-full border-b-2 border-l-2 border-r-2 border-dashed border-bg3 relative h-6">
                <IoIosArrowUp className="absolute left-[-13px] top-[-12px] text-bg3 text-2xl" />
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-24 md:mb-72 hidden">
          <div className="text-4xl md:text-6xl font-bold mb-12">
            My open <span className="text-pri">projects</span>
          </div>
          <div className="mb-3">
            All project are fully builded by me, frontend, backend,
            configuration and publication
          </div>
          <div className="sm:grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-16 mb-12">
            <div className="mb-8 sm:mb-0">
              <div className=" bg-bg1 rounded-lg overflow-hidden border-4 border-pri mb-2">
                <img src="/assets/project-1.png" alt="project 1" />
              </div>
              <div className="text-center">Description of project 1</div>
            </div>
            <div className="mb-8 sm:mb-0">
              <div className=" bg-bg1 rounded-lg overflow-hidden border-4 border-pri mb-2">
                <img src="/assets/project-1.png" alt="project 1" />
              </div>
              <div className="text-center">Description of project 1</div>
            </div>
            <div className="mb-8 sm:mb-0">
              <div className=" bg-bg1 rounded-lg overflow-hidden border-4 border-pri mb-2">
                <img src="/assets/project-1.png" alt="project 1" />
              </div>
              <div className="text-center">Description of project 1</div>
            </div>
          </div>
          <div>There are more but they are private...🤫</div>
          <a
            href="https://www.linkedin.com/in/rolly-rodriguez-02713b7a/"
            target="_blank"
          >
            <span className="text-sec font-bold italic">Send me a message</span>
          </a>
        </div>
        <div className="container mb-24 md:mb-72">
          <div className="md:flex">
            <div className="bg-envelop">
              <div className="text-4xl md:text-6xl font-bold mb-12 md:mb-24 ">
                Have a project in
                <br /> <span className="text-pri">mind?</span>
              </div>
              <div className="mb-3">Do not hesitate lets talk</div>
              <div className="mb-32">
                <a
                  className="btn btn-primary inline-block"
                  href="https://www.linkedin.com/in/rolly-rodriguez-02713b7a/"
                  target="_blank"
                >
                  Send me a message
                </a>
              </div>
              <div className="text-right font-bold">
                <div>and remember I can</div>
                <div className="text-4xl text-pri">do everything</div>
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-end items-center">
                <img
                  src="/assets/astronaut-message.png"
                  alt="astro"
                  className="w-[550px]"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="border-t border-bg4 pt-9 pb-48">
          <div className="container">
            <div className="sm:flex justify-center gap-8 text-center">
              <a
                className="flex items-center gap-2 mb-4 md:mb-0"
                href="https://github.com/rollrodrig"
                target="_blank"
              >
                <FaGithub /> Github
              </a>
              <a
                className="flex items-center gap-2 mb-4 md:mb-0"
                href="https://www.linkedin.com/in/rolly-rodriguez-02713b7a/"
                target="_blank"
              >
                <FaLinkedin /> LinkedIn
              </a>
              <a
                className="flex items-center gap-2 mb-4 md:mb-0"
                href="https://www.instagram.com/rollthecoder/"
                target="_blank"
              >
                <FaInstagram /> Instagram
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Home

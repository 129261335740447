import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './pages/home'
import Animation from './pages/animation'

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/animation" element={<Animation />} />
    </Routes>
  )
}

export default App

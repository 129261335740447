import React from 'react'

interface Props {
  tech: string
}

export const TechBullet = ({ tech }: Props) => {
  return (
    <div className="flex items-center gap-1">
      <img className="w-[18px]" src="/assets/check.png" alt={tech} />
      {tech}
    </div>
  )
}
export default TechBullet

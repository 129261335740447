import React, { useRef } from 'react'
import Assets from '../components/assets'

export function Animation() {
  return (
    <div className="bg-red-500">
      <Assets />
      <div className="absolute top-0 left-0 w-full z-20">
        <div className="w-96 h-96 border-2 border-white">content</div>
        <div className="w-96 h-96 border-2 border-white m-32">content</div>
        <div className="w-96 h-96 border-2 border-white m-32">content</div>
        <div className="w-96 h-96 border-2 border-white m-32">content</div>
        <div className="w-96 h-96 border-2 border-white m-32">content</div>
        <div className="w-96 h-96 border-2 border-white m-32">content</div>
        <div className="w-96 h-96 border-2 border-white m-32">content</div>
        <div className="w-96 h-96 border-2 border-white m-32">content</div>
      </div>
    </div>
  )
}
export default Animation

import React, { useRef } from 'react'
import { gsap } from 'gsap'
import { useGSAP } from '@gsap/react'
gsap.registerPlugin(useGSAP)

export function Assets() {
  const container = useRef<HTMLDivElement>(null)
  const astro = useRef<HTMLImageElement>(null)
  var astronautTl = gsap.timeline()
  var sparkleDotTl = gsap.timeline()
  var sparkleBgDotTL = gsap.timeline()
  let width = window.innerWidth
  const height = window.innerHeight

  useGSAP(() => {
    let fromX = width + width / 3
    let toX = width / 3
    let fromY = -1200
    let toY = -80

    let xMoveRangeFrom = 400
    let xMoveRangeTo = 500
    let yMoveRangeFrom = 0
    let yMoveRangeTo = -100

    let rotationFrom = -10
    let rotationTo = 10

    if (width < 960 && width > 760) {
      toX = width / 4
      fromY = -700
      toY = -20
      xMoveRangeFrom = width / 3
      xMoveRangeTo = width / 3 + (width / 3) * 0.1
      yMoveRangeTo = -50
    }
    if (width < 760) {
      toX = 0
      fromY = -200
      toY = 30
      xMoveRangeFrom = width / 4
      xMoveRangeTo = width / 4 + (width / 4) * 0.1
      yMoveRangeTo = -20
    }

    astronautTl
      .fromTo(
        astro.current,
        { x: fromX, y: fromY },
        { x: toX, y: toY, duration: 2, ease: 'back.out(1.7)' }
      )
      .to(astro.current, {
        x: `random(${xMoveRangeFrom}, ${xMoveRangeTo})`,
        y: `random(${yMoveRangeFrom}, ${yMoveRangeTo})`,
        rotation: `random(${rotationFrom}, ${rotationTo})`,
        duration: 2,
        ease: 'sine.inOut',
        repeat: -1,
        yoyo: true,
        repeatRefresh: true, // This will randomize the values on each repeat
        autoPlay: true,
      })

    sparkleBgDotTL.fromTo(
      '.sparkle-bg',
      {
        x: `random(${(width / 2) * -1}, ${width})`,
        y: `random(${height}, ${height + height * 0.2})`,
        opacity: 0,
      },
      {
        x: 'random(1200, 2000)',
        y: 'random(-100, -200)',
        opacity: 'random(0.1, 0.8)',
        duration: 'random(6, 10)',
        ease: 'sine.inOut',
        repeat: -1,
        repeatDelay: 1,
        stagger: 0.5,
        autoPlay: true,
      }
    )

    sparkleDotTl.fromTo(
      '.sparkle',
      {
        x: `random(${(width / 2) * -1}, ${width})`,
        y: `random(${height}, ${height + height * 0.2})`,
        opacity: 0,
      },
      {
        x: `random(${width}, ${width * 2})`,
        y: `random(-50, -100)`,
        opacity: 'random(0.9, 1)',
        duration: 2,
        ease: 'sine.inOut',
        repeat: -1,
        repeatDelay: 1,
        stagger: 0.5,
        zIndex: 'random(8, 11)',
        autoplay: true,
      }
    )
  })
  return (
    <div
      ref={container}
      className="overflow-hidden absolute w-full h-full top-0 left-0 min-h-[950px] z-10"
    >
      <img
        src="/assets/sparkle-bg-1.png"
        alt="sparkle"
        className="sparkle-bg absolute z-1"
      />
      <img
        src="/assets/sparkle-bg-2.png"
        alt="sparkle"
        className="sparkle-bg absolute z-1"
      />
      <img
        src="/assets/sparkle-bg-3.png"
        alt="sparkle"
        className="sparkle-bg absolute z-1"
      />
      <img
        src="/assets/sparkle-bg-4.png"
        alt="sparkle"
        className="sparkle-bg absolute z-1"
      />
      <img
        src="/assets/sparkle-bg-5.png"
        alt="sparkle"
        className="sparkle-bg absolute z-1"
      />

      <img
        ref={astro}
        src="/assets/astro.png"
        alt="astro"
        className="astro absolute z-10"
      />

      <img
        src="/assets/sparkle-1.png"
        alt="sparkle"
        className="sparkle absolute z-20"
      />
      <img
        src="/assets/sparkle-2.png"
        alt="sparkle"
        className="sparkle absolute z-20"
      />
      <img
        src="/assets/sparkle-3.png"
        alt="sparkle"
        className="sparkle absolute z-20"
      />
      <img
        src="/assets/sparkle-4.png"
        alt="sparkle"
        className="sparkle absolute z-20"
      />
    </div>
  )
}
export default Assets
